requirejs.config({
	baseUrl: "/",
	paths: {
		//directories:
	    lib: "lib",
	    jquery: "https://ajax.googleapis.com/ajax/libs/jquery/1.11.3/jquery.min",
	    jqueryui: "https://ajax.googleapis.com/ajax/libs/jqueryui/1.10.2/jquery-ui.min",
	    bootstrap: "https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min",
	    xlsx: "https://unpkg.com/xlsx/dist/xlsx.full.min",
	    sentry: [
			"https://browser.sentry-cdn.com/6.19.7/bundle.min",
			// fallback to local file - some ad blockers (eg. uBlock Origin) block sentry's CDN
			"lib/sentry-6.19.7.min"
		],
		Dwr: "dwr/engine.js?src=requirejs",
		OUtils: "js/OUtils",
		OTranslate: "js/OTranslate",
		OApp: "js/OApp",
		Patient: "js/Patient",
		OEncryption: "js/OEncryption",
		FileUpload: "lib/jquery/OJQueryFileUpload.min",
		i18n: "lib/i18next/i18next-1.11.5.min",
		Switchery: "lib/components/switchery/dist/switchery.min",
		moment: "https://cdn.jsdelivr.net/npm/moment@2.29.1/min/moment-with-locales.min",
		momentTZ: "https://cdn.jsdelivr.net/npm/moment-timezone@0.5.33/builds/moment-timezone-with-data.min" // Need TZ data too. Expects "moment" in lowercase
	},
	shim: {//externally-defined dependencies and modules (for optimization and external libs)
		jqueryui: ["jquery", "bootstrap"],
		OUtils: ["jqueryui"],
		OEncryption: ["lib/OEncryptionLibs"],
		i18n: ["jquery"],
		FileUpload: ["jqueryui"],
		bootstrap: ["jquery"],
	},
	waitSeconds: 30
});

require(["Dwr"], function() {
  
  	/**
	 * Call at the beginning of your application, the sooner the better. Until it is called, the 
	 * server logs will not be decorated by window identifier. In itself, the ID has no meaning, other
	 * than different windows/tabs will almost certainly have different IDs.
	 */
	function initWindowId(){
		
		if (win["ocean_id_initialized"]) {
			return;
		}
		win["ocean_id_initialized"] = true;
	
		const originalFetch = win.fetch;
		
		win.fetch = async function(resource, init = {}) {
			// Ensure init.headers exists
			init.headers = init.headers || {};
			
			// If headers is a plain object, convert it to an instance of Headers
			if (!(init.headers instanceof Headers)) {
				init.headers = new Headers(init.headers);
			}
			
			// Add the windowID to the headers
			init.headers.set('WNDID', getWindowId());
			
			// Call the original fetch function with the modified init object
			return originalFetch(resource, init);
		};
		
	    // Store the original DWR _execute function
	    let originalExecute = dwr.engine._execute;
	
	    // Override the DWR _exee function
	    dwr.engine._execute = function() {
			// Add custom headers
			dwr.engine._headers = dwr.engine._headers || {};
			dwr.engine._headers['WNDID'] = getWindowId();
			
			// Call the original _execute function
			return originalExecute.apply(this, arguments);
	    };
	}
	
	var win = window;
		
	initWindowId();
	
	/**
	 * Local helper function. Returns random ID assigned to this window/tab, so we can see it in the server log.
	 */
	function getWindowId() {
		if (!win["OceanWindowID"])
		{
			const rand = Math.floor(Math.random() * Math.pow(2, 32)).toString(16);
			win["OceanWindowID"] = rand;
		}
		return win["OceanWindowID"];
	}
	
	
});